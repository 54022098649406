<template>
  <div>
    <ModalBackground
      :isActive="isActive"
      :closesModal="hidesModalOnOutsideClick"
      @close="hideModal"
    ></ModalBackground>
    <ModalContent
      :isActive="isActive"
      :isRemovalPending="IS_MODAL_REMOVAL_PENDING"
    >
      <component :is="componentName" @close="hideModal"></component>
    </ModalContent>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import {
  Module,
  CURRENT_MODAL,
  IS_MODAL_REMOVAL_PENDING,
  TRIGGER_MODAL_REMOVAL,
  HIDE_MODAL,
  Digit,
  MODAL,
} from '@/constants'
import * as ModalName from '@/constants/modalName'
import { isNil, requestTimeout, cancelTimeout, callFn, dest } from '@/helpers'

const requestInitialValue = null

export default {
  name: MODAL,
  components: {
    ModalBackground: () => import('./components/ModalBackground'),
    ModalContent: () => import('./components/ModalContent'),
    [ModalName.REALITY_CHECK_MODAL]: () => import('@/modals/RealityCheck'),
    [ModalName.LOGIN_MODAL]: () => import('@/modals/Login'),
    [ModalName.RESEND_CONFIRMATION_EMAIL_MODAL]: () =>
      import('@/modals/ResendConfirmationEmail'),
    [ModalName.FORGOT_PASSWORD_MODAL]: () => import('@/modals/ForgotPassword'),
    [ModalName.STORE_UPDATE_MODAL]: () => import('@/modals/StoreUpdate'),
    [ModalName.PAY_AND_PLAY_MODAL]: () => import('@/modals/PayAndPlay'),
    [ModalName.REGISTER_MODAL]: () => import('@/modals/Register'),
    [ModalName.PNP_ACCOUNT_MODAL]: () => import('@/modals/PnpAccount'),
    [ModalName.PAY_AND_PLAY_SESSION_DETAILS]: () =>
      import('@/modals/PayAndPlaySessionDetails'),
  },
  data: () => ({
    modalHidingRequest: requestInitialValue,
  }),
  computed: {
    ...mapState(Module.MODAL, { IS_MODAL_REMOVAL_PENDING }),
    ...mapGetters({ currentModal: dest([Module.MODAL, CURRENT_MODAL]) }),
    isActive() {
      return !isNil(this.currentModal)
    },
    hidesModalOnOutsideClick() {
      return !this.currentModal?.isStrict
    },
    componentName() {
      return this.currentModal?.component
    },
  },
  watch: {
    currentModal(value, prevValue) {
      if (
        !isNil(prevValue) &&
        value?.component !== prevValue?.component &&
        this.modalHidingRequest
      ) {
        this.clearModalHiding()
      }
    },
  },
  methods: {
    ...mapMutations(Module.MODAL, [HIDE_MODAL]),
    ...mapMutations(Module.MODAL, [TRIGGER_MODAL_REMOVAL]),
    hideModal(fn) {
      const handler = () => {
        this.HIDE_MODAL()
        callFn(fn)
      }

      this.TRIGGER_MODAL_REMOVAL()
      this.$nextTick(() => {
        this.modalHidingRequest = requestTimeout(handler, Digit.TWO_HUNDRED)
      })
    },
    async clearModalHiding() {
      await cancelTimeout(this.modalHidingRequest)
      this.modalHidingRequest = requestInitialValue
    },
  },
}
</script>
